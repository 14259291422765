import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { generalPath } from './constants/routes';
import { AuthGuard } from '@deuna/bo-sh-helpers-ng';
import { AuthorizationGuard } from '@deuna/bo-sh-security-ng';
import { MerchantRoles } from '@deuna/ng-bo-roles';

const routes: Routes = [
  {
    path: generalPath,
    //component: EmptyRouteComponent
    loadChildren: () =>
      import('./modules/campaign/campaign.module').then(
        (module) => module.CampaignModule
      ),
    canLoad: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard],
    canActivate: [AuthorizationGuard],
    data: {
      requiredRoles: [MerchantRoles.QRMASSIVE_READER],
    },
  },
  {
    path: '**',
    component: EmptyRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
